import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import { cn } from '@utils/cn';
import { Label } from '@/components/ui/label';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { GoInfo } from 'react-icons/go';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, checked, ...props }, ref) => {
  if (checked === undefined) {
    checked = false;
  }
  return (
    <CheckboxPrimitive.Root
      checked={checked}
      ref={ref}
      className={cn(
        'peer h-5 w-5 shrink-0 rounded-sm border border-ring bg-inherit ring-offset-background',
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        {
          'data-[state=checked]:border-primary data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground':
            !props.disabled,
          'disabled:border-gray-300 disabled:bg-gray-300 disabled:text-gray-900': props.disabled,
        },
        className
      )}
      {...props}>
      <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
        <Check className="h-5 w-5" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

type CheckboxWithLabelProps = React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
  label: string;
  name: string;
  tooltip?: React.ReactNode;
  id?: string;
};

const CheckboxWithLabel = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxWithLabelProps>(
  ({ label, className, name, id, checked, onChange, tooltip, ...props }, ref) => {
    if (!id) {
      id = name;
    }
    return (
      <TooltipProvider>
        <div className="flex items-start space-x-2">
          <Checkbox
            {...props}
            ref={ref}
            className={className}
            checked={checked}
            onCheckedChange={(checked) => {
              if (onChange) {
                onChange({ target: { checked, name, id } });
              }
            }}
            id={id}
            name={name}
          />
          <div className="flex items-center space-x-1.5 peer-disabled:font-light peer-disabled:text-gray-500">
            <Label
              htmlFor={id}
              className="leading-none">
              {label}
            </Label>
            {tooltip && (
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <div className="text-lg text-secondary">
                    <GoInfo
                      className="cursor-pointer"
                      size={13}
                    />
                  </div>
                </TooltipTrigger>
                <TooltipContent
                  side="top"
                  align="center"
                  className="m-4 mx-8 max-w-4xl break-words border border-secondary bg-secondary font-karla text-lg text-white">
                  {typeof tooltip === 'string' ? <p>{tooltip}</p> : tooltip}
                </TooltipContent>
              </Tooltip>
            )}
          </div>
        </div>
      </TooltipProvider>
    );
  }
);
CheckboxWithLabel.displayName = 'CheckboxWithLabel';

type CheckboxWithLabelAndTextProps = React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
  label: string;
  text: React.ReactNode;
  name: string;
  tooltip?: React.ReactNode;
  id?: string;
};

const CheckboxWithLabelAndText = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxWithLabelAndTextProps
>(({ label, text, className, id, name, checked, onChange, tooltip, ...props }, ref) => {
  if (!id) {
    id = name;
  }
  return (
    <TooltipProvider>
      <div className="flex items-start space-x-2">
        <Checkbox
          {...props}
          ref={ref}
          className={className}
          checked={checked}
          onCheckedChange={(checked) => {
            if (onChange) {
              onChange({ target: { checked, name } });
            }
          }}
          id={id}
          name={name}
        />
        <div className="grid gap-1.5 leading-none peer-disabled:font-light peer-disabled:text-gray-500">
          <div className="flex items-start space-x-1.5">
            <Label
              htmlFor={id}
              className="leading-none">
              {label}
            </Label>
            {tooltip && (
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <div className="text-lg text-secondary">
                    <GoInfo
                      className="cursor-pointer"
                      size={13}
                    />
                  </div>
                </TooltipTrigger>
                <TooltipContent
                  side="top"
                  align="center"
                  className="m-4 mx-8 max-w-4xl break-words border border-secondary bg-secondary font-karla text-lg text-white">
                  {typeof tooltip === 'string' ? <p>{tooltip}</p> : tooltip}
                </TooltipContent>
              </Tooltip>
            )}
          </div>
          {typeof text === 'string' ? <p className="text-sm text-muted-foreground">{text}</p> : text}
        </div>
      </div>
    </TooltipProvider>
  );
});
CheckboxWithLabelAndText.displayName = 'CheckboxWithLabelAndText';

export { Checkbox, CheckboxWithLabel, CheckboxWithLabelAndText };
