import * as React from 'react';
import { cn } from '@utils/cn';
import { Input } from '@/components/ui/input';
import { Label, LabelWithTooltip } from '@/components/ui/label';
import { Button } from '@/components/ui/button'; // Ensure you have a Button component imported

export interface ColorPickerProps {
  id: string;
  title: string;
  value: string | null | undefined;
  defaultValue: string;
  onColorChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onColorClear: () => void;
  disabledClear?: boolean;
  disabled?: boolean;
  inputClassName?: string;
  labelClassName?: string;
  wrapperClassName?: string;
  tooltipClassName?: string;
  tooltip?: React.ReactNode;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  id,
  title,
  value,
  defaultValue,
  onColorChange,
  onColorClear,
  disabledClear = false,
  disabled = false,
  inputClassName,
  labelClassName,
  wrapperClassName,
  tooltipClassName,
  tooltip,
}) => {
  if (typeof disabled === 'undefined') disabled = disabledClear;
  return (
    <div className={cn('flex flex-col gap-1', wrapperClassName)}>
      <LabelWithTooltip
        className={cn('font-medium', {
          'text-gray-500': disabled,
        })}
        tooltip={tooltip}
        tooltipClassName={tooltipClassName}>
        {title}
      </LabelWithTooltip>
      <div className="flex items-start gap-2">
        <Input
          id={id}
          name={id}
          type="color"
          value={value || defaultValue}
          className={cn(
            'h-7 w-20 cursor-pointer p-0',
            {
              'border-none': value && value?.toLowerCase() !== '#ffffff',
            },
            {
              'cursor-not-allowed': disabled,
            },
            inputClassName
          )}
          onChange={onColorChange}
        />
        <Label
          htmlFor={id}
          className={cn(
            'cursor-pointer text-black',
            {
              'text-gray-500': disabled,
            },
            labelClassName
          )}>
          Choose
        </Label>
        <Button
          name={id}
          variant="plain"
          size="sm"
          className="h-7 bg-inherit px-1 font-normal"
          disabled={disabledClear || !value}
          onClick={onColorClear}>
          Clear
        </Button>
      </div>
    </div>
  );
};

export { ColorPicker };
