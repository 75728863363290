import * as React from 'react';
import { cn } from '@utils/cn';
import { Label } from '@/components/ui/label'; // Ensure you have the Label component imported
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { GoInfo } from 'react-icons/go';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        'flex h-10 w-full rounded-md border border-input bg-background px-3 py-6 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
        'font-karla',
        'focus-visible:border-primary focus-visible:ring-0',
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = 'Input';

export interface InputWithLabelProps extends InputProps {
  label: string;
  labelClassName?: string;
  inputClassName?: string;
  wrapperClassName?: string;
  id: string;
  tooltip?: React.ReactNode;
}

const InputWithLabel: React.FC<InputWithLabelProps> = ({
  label,
  labelClassName,
  inputClassName,
  wrapperClassName,
  id,
  tooltip,
  disabled,
  ...inputProps
}) => {
  return (
    <TooltipProvider>
      <div className={cn('flex flex-col gap-1', wrapperClassName)}>
        <div className="flex items-center space-x-1">
          <Label
            className={cn('text-lg', disabled && 'cursor-not-allowed text-gray-700 opacity-70', labelClassName)}
            htmlFor={id}>
            {label}
          </Label>
          {tooltip && (
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <div className="font-lg text-secondary">
                  <GoInfo
                    className="cursor-pointer"
                    size={13}
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent
                side="top"
                align="center"
                className="m-4 mx-8 max-w-4xl break-words bg-secondary font-karla text-lg text-white">
                {typeof tooltip === 'string' ? <p>{tooltip}</p> : tooltip}
              </TooltipContent>
            </Tooltip>
          )}
        </div>
        <Input
          name={id}
          className={cn('w-full', inputClassName)}
          id={id}
          disabled={disabled}
          {...inputProps}
        />
      </div>
    </TooltipProvider>
  );
};

const InputWithSubLabel: React.FC<InputWithLabelProps> = ({
  label,
  labelClassName,
  inputClassName,
  wrapperClassName,
  id,
  disabled,
  ...inputProps
}) => {
  return (
    <div className={cn('flex flex-col gap-1', wrapperClassName)}>
      <Input
        name={id}
        className={cn('w-full', inputClassName)}
        id={id}
        disabled={disabled}
        {...inputProps}
      />
      <Label
        className={cn(
          'font-light text-gray-900',
          disabled && 'cursor-not-allowed text-gray-700 opacity-70',
          labelClassName
        )}
        htmlFor={id}>
        {label}
      </Label>
    </div>
  );
};

export { Input, InputWithLabel, InputWithSubLabel };
