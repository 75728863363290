interface CacheData {
  currentDatasheetFromParameter: string | undefined;
}

class Cache {
  private static instance: Cache;
  private store: Map<keyof CacheData, CacheData[keyof CacheData]>;

  private constructor() {
    this.store = new Map<keyof CacheData, CacheData[keyof CacheData]>();
  }

  public static getInstance(): Cache {
    if (!Cache.instance) {
      Cache.instance = new Cache();
    }
    return Cache.instance;
  }

  public set<K extends keyof CacheData>(key: K, value: CacheData[K]): void {
    this.store.set(key, value);
  }

  public get<K extends keyof CacheData>(key: K): CacheData[K] | undefined {
    return this.store.get(key);
  }

  public remove<K extends keyof CacheData>(key: K): void {
    this.store.delete(key);
  }

  public clear(): void {
    this.store.clear();
  }

  public getAllKeys(): (keyof CacheData)[] {
    return Array.from(this.store.keys());
  }
}

export default Cache;
