export enum Time {
  DAYS = 0,
  HOURS = 1,
  MINUTES = 2,
  SECONDS = 3,
  MILLIS = 4,
}

export type TimeRange = [Time, Time];
/**
 * default time range for use in ./grid/formatters.js and ./components/time-range.js
 * @note this is a stange location for this but idk where else to put this.
 *
 */
export const defaultTimeRange: TimeRange = [Time.MINUTES, Time.MILLIS];
